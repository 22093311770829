import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Chip,
  Pagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Videos, Sidebar } from "./";
import AdBanner from "./AdBanner/AdBanner";
import AdBannerMobile from "./AdBanner/AdBannerMobile";
import {
  fetchFreeVideos,
  fetchNewVideos,
  fetchTopViewedVideos,
  fetchVideosByCategory,
  fetchVideosStream,
  fetchVipVideos,
} from "./api/video/videoSlice";
import { useDispatch, useSelector } from "react-redux";
import StreamingVideos from "./StreamingVideo/StreamingVideo";
import { useCategory } from "../context/CategoryContext";

const Feed = () => {
  const { selectedCategory, setSelectedCategory, selectedCategoryId, setSelectedCategoryId } = useCategory();
  const [adVisible, setAdVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(1);

  const { videoCategory, streamingVideos, loadingCategory, errorCategory } =
    useSelector((state) => state.videos);
  const limit = 30;
  console.log("Limit: ", limit);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Selected category: ", selectedCategory);
    if (selectedCategoryId === "1") {
      dispatch(fetchTopViewedVideos({ page, limit }));
    } else if (selectedCategoryId === "2") {
      dispatch(fetchNewVideos({ page, limit }));
    } else if (selectedCategoryId === "3") {
      dispatch(fetchFreeVideos({ page, limit }));
    } else if (selectedCategoryId === "4") {
      dispatch(fetchVipVideos({ page, limit }));
    } else if (selectedCategoryId === "5") {
      dispatch(fetchVideosStream({ page, limit }));
    } else {
      dispatch(
        fetchVideosByCategory({
          categoryId: selectedCategoryId,
          page,
          limit,
        })
      );
    }
  }, [selectedCategoryId, page]);
  useEffect(() => {
    setPage(1);
  }, [selectedCategoryId]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const tags = [
    "Tech",
    "Music",
    "Gaming",
    "News",
    "Education",
    "Movies",
    "Sports",
    "Science",
    "Health",
    "Travel",
    "Lifestyle",
    "Finance",
    "Fashion",
    "Art",
    "Food",
    "Politics",
    "Business",
    "Photography",
    "Coding",
    "Fitness",
  ];

  return (
    <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
      <Box
        sx={{
          height: { sx: "auto", md: "92vh" },
          borderRight: "1px solid #3d3d3d",
          px: { sx: 0, md: 2 },
        }}
      >
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedCategoryId={setSelectedCategoryId}
          selectedCategoryId={selectedCategoryId}
        />

        <Typography
          className="copyright"
          variant="body2"
          sx={{ mt: 1.5, color: "#fff" }}
        >
          Vichain.net © 2024
        </Typography>
      </Box>

      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          mb={2}
          sx={{ color: "white" }}
        >
          {selectedCategory} <span style={{ color: "#F79A24" }}>videos</span>
        </Typography>

        <Box p={2}>
          <Box
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <Stack direction="row" spacing={0.5}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  sx={{
                    borderRadius: 0,
                    backgroundColor: "#fbc50c",
                    "&:hover": {
                      backgroundColor: "#d4a000",
                    },
                  }}
                />
              ))}
            </Stack>
          </Box>
        </Box>
        {selectedCategoryId === "5"
          ? streamingVideos && <StreamingVideos videos={streamingVideos?.data} />
          : videoCategory?.documents && (
              <Videos videos={videoCategory.documents} />
            )}

        {selectedCategoryId === "5" ? (<Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={streamingVideos?.totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{
              "& .MuiPaginationItem-root": {
                fontWeight: "bold",
                color: "#fff",
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "#f79a24",
                color: "#fff",
              },
            }}
          />
        </Box>):(<Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={videoCategory?.totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{
              "& .MuiPaginationItem-root": {
                fontWeight: "bold",
                color: "#fff",
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "#f79a24",
                color: "#fff",
              },
            }}
          />
        </Box>)}

        {/* Quảng cáo */}
        {adVisible && (
          <Box
            sx={{
              position: "fixed",
              top: "10%",
              right: 0,
              transform: "translateX(0%)",
              width: isMobile ? "200px" : "320px",
              height: isMobile ? "40px" : "50px",
              zIndex: 1000,
              backgroundColor: "#f0f0f0",
              display: "block",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1001,
              }}
              onClick={() => setAdVisible(false)}
            >
              <CloseIcon />
            </IconButton>
            {isMobile ? <AdBannerMobile /> : <AdBanner />}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default Feed;
