import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  Stack,
  Button,
  styled,
  Grid,
  Modal,
} from "@mui/material";
import {
  useAccount,
  useConnect,
  useSendTransaction,
  useSignMessage,
  useWaitForTransactionReceipt,
} from "wagmi";
import { demoProfilePicture } from "../utils/constants";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { fetchFromAPI } from "../utils/fetchFromAPI";
import Videos from "./Videos";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, updateUser } from "./api/user/userUpdate";
import { fetchLikedVideos, fetchUserVideos } from "./api/video/videoSlice";
import UserPlayList from "./Playlist/UserPlayList";
import { fetchVideos } from "./api/creator/videoCreatorSlice";
import axios from "axios";
import Web3Modal from "web3modal";
import { parseEther } from "viem";
import DepositModal from "./ModalCustom/DepositModal";

const UserCard = ({ marginTop }) => {
  const { userData } = useSelector((state) => state.user);
  const { address, uid, isConnected } = useAccount();
  const [avatar, setAvatar] = useState(demoProfilePicture); // Ảnh avatar ban đầu
  const [avatarFile, setAvatarFile] = useState(null); // Lưu trữ tệp ảnh
  const [value, setValue] = React.useState("1");
  const [channelDetail, setChannelDetail] = useState();
  const [videos, setVideos] = useState(null);
  const { connect, connectors } = useConnect(); // Kết nối ví bằng wagmi
  const { user, token } = useSelector((state) => state.auth);
  const { likedVideos } = useSelector((state) => state.videos);
  const { videosCreator, loadingvideosCreator, errorvideosCreator } =
    useSelector((state) => state.videosCreator);
  // const { videosData, statusVideo, errorVideo } = useSelector((state) => state.videos);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [priceBNB, setPriceBNB] = useState("");
  const [exchangeRate, setExchangeRate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDepositModal, setOpenDepositModal] = useState(false); // Điều khiển hiển thị popup
  const [usdtAmount, setUsdtAmount] = useState("");
  const {
    sendTransaction,
    data: hash,
    isPending,
    error,
  } = useSendTransaction();
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });
  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,tether&vs_currencies=usd"
      );
      const rateUSDTToBNB =
        response.data.tether.usd / response.data.binancecoin.usd;
      setExchangeRate(rateUSDTToBNB); // Lưu tỷ giá vào state
      setLoading(false); // Đặt trạng thái đã tải xong
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExchangeRate(); 
  }, []);

  const handleDeposit = async () => {
    if (!isConnected) {
      // Nếu chưa kết nối ví, mở giao diện kết nối ví
      const web3Modal = new Web3Modal();
      const instance = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
      await provider.send("eth_requestAccounts", []);
    } else {
      // Nếu đã kết nối ví, mở popup nhập số USDT
      setOpenDepositModal(true);
    }
  };
  const balanceBNB = exchangeRate
    ? (userData.balanceUSDT * exchangeRate).toFixed(4)
    : 0;
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    const userInfo = {
      avatar,
      email,
      fullName,
      phoneNumber,
      priceBNB,
    };
    dispatch(updateUser({ userInfo, token }));
  };
  console.log("Connected", address, uid, isConnected);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log("User Data", userData);
  useEffect(() => {
    dispatch(fetchLikedVideos({ token, page: 1, limit: 10 }));
    dispatch(
      fetchVideos({ shortLinkUser: userData.shortUrl, page: 1, limit: 10 })
    );
  }, [dispatch]);
  useEffect(() => {
    if (userData) {
      setEmail(userData.email);
      setFullName(userData.fullName);
      setPhoneNumber(userData.phoneNumber);
      setPriceBNB(userData.priceBNB);
      // setAvatar(userData.avatar);
      const shortlinkUser = userData.shortUrl;
      dispatch(fetchUserVideos({ shortlinkUser, page: 1, limit: 10 }));
    }
  }, [userData]);

  useEffect(() => {
    dispatch(fetchUserProfile({ token }));
  }, []);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(URL.createObjectURL(file)); // Cập nhật preview avatar
      setAvatarFile(file); // Lưu trữ tệp ảnh
    }
  };

  useEffect(() => {
    console.log("Status", isPending, hash, isConfirming, isConfirmed);
  }, [isPending, hash, isConfirming, isConfirmed]);

  const handleSubmitDeposit = async () => {
    console.log("Nạp số USDT: ", usdtAmount);
  
    if (address) {
      try {
        // Gọi CoinGecko API để lấy tỷ giá giữa USDT và BNB
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,tether&vs_currencies=usd"
        );
  
        // Lấy giá BNB và USDT theo USD
        const bnbPrice = response.data.binancecoin.usd;
        const usdtPrice = response.data.tether.usd;
  
        // Tính số BNB tương đương với số USDT
        const bnbAmount = (usdtAmount * usdtPrice) / bnbPrice;
  
        console.log("Số BNB sẽ chuyển: ", bnbAmount);
  
        // Thực hiện giao dịch BNB
        sendTransaction({
          to: "0x342ED96AF4FccA7229b93CcB0F39A8E2E6B8BD97", // Địa chỉ nhận BNB
          value: parseEther(bnbAmount.toString()), // Chuyển đổi số BNB thành định dạng BigNumber cho giao dịch
          onSuccess(data) {
            console.log("Giao dịch thành công", data);
          },
          onError(error) {
            console.error("Giao dịch thất bại", error);
          },
        });
      } catch (error) {
        console.error("Lỗi khi lấy tỷ giá từ CoinGecko", error);
      }
    } else {
      connect(connectors[0]); // Kết nối ví nếu chưa kết nối
    }
  };

  // useEffect(() => {
  //   if (address) {
  //     console.log("Address", address);

  //     dispatch(getNonce(address));
  //   }

  // }, [address])

  // useEffect(() => {
  //   if (nonceData) {
  //     console.log("nonceData", nonceData.nonce);
  //     handleLogin(nonceData.nonce);
  //   }

  // }, [nonceData])
  // useEffect(() => {
  //   if (hash && address) {
  //     console.log("Address", address);
  //     dispatch(login({ addressKey: address, signature: hash }));
  //   }
  // }, [hash, address]);

  const handleSave = () => {
    handleSubmit();
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleWithdraw = () => {
    console.log("Withdraw button clicked");
  };
  return (
    <>
      <Box
        sx={{
          boxShadow: "none",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "90%", sm: "75%", md: "60%" },
          height: "auto",
          margin: "auto",
          marginTop,
          flexDirection: "column",
          backgroundColor: "#242526",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Grid container spacing={3}>
          {/* Phần 1: Thông tin Profile */}
          <Grid item xs={12} md={6}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                textAlign: "center",
                color: "#fff",
                alignItems: "center",
              }}
            >
              <Box component="label" sx={{ cursor: "pointer" }}>
                <CardMedia
                  component="img"
                  image={avatar}
                  alt="Avatar"
                  sx={{
                    borderRadius: "50%",
                    height: { xs: "120px", sm: "150px", md: "180px" },
                    width: { xs: "120px", sm: "150px", md: "180px" },
                    mb: { xs: 2, sm: 0 },
                    marginRight: { sm: 3, md: 4 },
                    transition: "opacity 0.3s",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                />
              </Box>
              <div>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", sm: "14px", md: "15px" },
                    fontWeight: 500,
                    color: "white",
                    marginBottom: 1,
                  }}
                >
                  Profile Image
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "10px", sm: "12px", md: "13px" },
                    color: "#AAAAAA",
                  }}
                >
                  We recommend an image of at least 300x300. GIFs work too. Max
                  5MB.
                </Typography>
              </div>
            </CardContent>

            {/* Thông tin thêm */}
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <TextField
                id="outlined-username"
                label="Full name"
                variant="filled"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                sx={{
                  width: "100%",
                  backgroundColor: "#1A1C1F",
                  "& .MuiInputBase-input": {
                    color: "#FFFFDB",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  borderRadius: "10px",
                  marginBottom: 2,
                }}
              />
              <TextField
                id="outlined-email"
                label="Email"
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  width: "100%",
                  backgroundColor: "#1A1C1F",
                  "& .MuiInputBase-input": {
                    color: "#FFFFDB",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  borderRadius: "10px",
                  marginBottom: 2,
                }}
              />
              <TextField
                id="outlined-phone"
                label="Phone number"
                variant="filled"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{
                  width: "100%",
                  backgroundColor: "#1A1C1F",
                  "& .MuiInputBase-input": {
                    color: "#FFFFDB",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  borderRadius: "10px",
                  marginBottom: 2,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#fbc50c",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#d4a000",
                  },
                }}
              >
                Save
              </Button>
            </Stack>
          </Grid>

          {/* Phần 2: Balance, Deposit, Withdraw */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: "#333",
                borderRadius: "10px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <w3m-button />

              <Typography variant="h6" sx={{ color: "#fff", marginBottom: 1 }}>
                Total Balance
              </Typography>
              <Typography variant="h5" sx={{ color: "#fbc50c" }}>
                ${userData.balanceUSDT.toFixed(2)} USDT
              </Typography>

              {loading ? (
                <Typography
                  variant="h6"
                  sx={{ color: "#fbc50c", marginTop: 1 }}
                >
                  Loading BNB balance...
                </Typography>
              ) : (
                <Typography sx={{ color: "#fbc50c" }}>
                  {balanceBNB} BNB
                </Typography>
              )}

              <Stack spacing={2} alignItems="center" direction="row" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleDeposit}
                  sx={{
                    borderRadius: "10px",
                    color: "black",
                    backgroundColor: "#fbc50c",
                    "&:hover": {
                      backgroundColor: "#d4a000",
                    },
                  }}
                >
                  Deposit
                </Button>
                <Button
                  variant="contained"
                  onClick={handleWithdraw}
                  sx={{
                    borderRadius: "10px",
                    color: "black",
                    backgroundColor: "#fbc50c",
                    "&:hover": {
                      backgroundColor: "#d4a000",
                    },
                  }}
                >
                  Withdraw
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Tab Sections */}
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                color: "#ffffff",
                "&.Mui-selected": {
                  color: "#1A73E8",
                },
                "&:hover": {
                  color: "#1A73E8",
                  opacity: 0.8,
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#1A73E8",
              },
            }}
          >
            <Tab label="My Playlist" value="1" />
            <Tab label="My Video" value="2" />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{
            backgroundColor: "#1A1C1F",
            borderRadius: "8px",
            padding: { xs: 2, sm: 3, md: 4 },
            color: "#ffffff",
          }}
        >
          <Box p={2} display="flex">
            <UserPlayList videos={likedVideos.documents} />
          </Box>
        </TabPanel>
        <TabPanel
          value="2"
          sx={{
            backgroundColor: "#1A1C1F",
            borderRadius: "8px",
            padding: { xs: 2, sm: 3, md: 4 },
            color: "#ffffff",
          }}
        >
          <Box p={2} display="flex">
            <UserPlayList videos={videosCreator?.documents} />
          </Box>
        </TabPanel>
      </TabContext>
      <DepositModal
        openDepositModal={openDepositModal}
        setOpenDepositModal={setOpenDepositModal}
        usdtAmount={usdtAmount}
        setUsdtAmount={setUsdtAmount}
        handleSubmitDeposit={handleSubmitDeposit}
        isConfirming={isConfirming}
        isConfirmed={isConfirmed}
        transactionHash={hash}
        addressFrom={address}
        exchangeRate={exchangeRate}
      />
    </>
  );
};

export default UserCard;
