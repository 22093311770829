import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography, Box, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAccount, useConnect, useSendTransaction, useWaitForTransactionReceipt } from 'wagmi';
import { parseEther } from "viem";
import { fetchLinkLiveStream } from "../api/video/videoSlice";
import { useDispatch, useSelector } from "react-redux";

const VideoStreaming = () => {
  const { address } = useAccount();
  const { connect, connectors } = useConnect();
const dispatch = useDispatch();
  const { sendTransaction, data: hash, isPending, error } = useSendTransaction();
  const { linkstream, view, name, image, dataCookie} = useLocation().state || {};
  const { id } = useParams();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash });
  const { linkStreamingVideos } = useSelector((state) => state.videos);
  const handlePayToWatch = () => {
    if (address) {
      sendTransaction({
        to: '0x67592c9B99Ccc92dAF60C7F2D23303Ccd2AEe26B',
        value: parseEther("0.0001"),
        onSuccess(data) {
          console.log('Transaction successful', data);
        },
        onError(error) {
          console.error('Transaction failed', error);
        }
      });
    } else {
      connect(connectors[0]);
    }
  };
  console.log("Id0", id);

  useEffect(() => {
    dispatch(fetchLinkLiveStream({id}));
  }, [id]);

  return (
    <Box minHeight="95vh">
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box flex={1}>
          <Box sx={{ width: "100%", position: "sticky", top: "86px" }}>
            <ReactPlayer url={linkStreamingVideos?.linkstream} playing className="react-player" controls />
            <Typography color="#fff" variant="h5" fontWeight="bold" p={2}>
              {linkStreamingVideos?.name}
            </Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ color: "#fff" }} py={1} px={2} >
              <Link to={`/channel/${linkStreamingVideos?.name}`}>
                <Typography variant={{ sm: "subtitle1", md: 'h6' }}  color="#fff" >
                  {linkStreamingVideos?.name}
                  <CheckCircleIcon sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
                </Typography>
              </Link>
              <Stack direction="row" gap="20px" alignItems="center">
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {parseInt(linkStreamingVideos?.view).toLocaleString()} views
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {parseInt(linkStreamingVideos?.view).toLocaleString()} likes
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        {/* <Box px={2} py={{ md: 1, xs: 5 }} justifyContent="center" alignItems="center" >
          <Videos videos={videos} direction="column" />
        </Box> */}
      </Stack>
    </Box>
  );
};

export default VideoStreaming;
