import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, logout } from '../api/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Login = () => {
  const dispatch = useDispatch();
  const { user, token, status, error } = useSelector((state) => state.auth);

  const handleGoogleLogin = () => {
    window.location.href = `${API_BASE_URL}/api/auth/google`;
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = '/'; 
  };

  const navigate = useNavigate();
console.log("Token",token);
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUserId = localStorage.getItem('userId');

    if (storedToken && storedUserId) {
      dispatch(setUser({ token: storedToken, userId: storedUserId }));
    }

    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');
    const userIdFromUrl = queryParams.get('userId');

    if (tokenFromUrl && userIdFromUrl) {
      dispatch(setUser({ token: tokenFromUrl, userId: userIdFromUrl }));
      navigate('/');
    }
  }, [dispatch, navigate]);

  return (
    <div>
      {status === 'loading' && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      
      {user ? (
          <Button color="warning" onClick={handleLogout} variant="outlined">Logout</Button>
      ) : (
        <Button  variant="outlined"  color="error" onClick={handleGoogleLogin}  startIcon={<GoogleIcon />}>Login</Button>
      )}
    </div>
  );
};

export default Login;
