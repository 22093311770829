// components/ViewVideoPlayer.js
import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";
import VideoRelative from "../VideoRelative/VideoRelative";
import AdBannerMobile from "../AdBanner/AdBannerMobile";
import AdBanner from "../AdBanner/AdBanner";
import CloseIcon from "@mui/icons-material/Close";
import { fetchRelatedVideos, likeVideo } from "../api/video/videoSlice";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { demoChannelUrl } from "../../utils/constants";

const ViewVideoPlayer = () => {
  const videoRef = useRef(null);
  const { id } = useParams();
  const { userId, title, numOfView, numOfLike, enumMode, tags } = useLocation().state || {};
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [adVisible, setAdVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [adMobileVisible, setAdMobileVisible] = useState(true);
  const [adWebVisible, setAdWebVisible] = useState(true);
  const { dataRelateVideo } = useSelector((state) => state.videos);
  const { user,token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  
  const handleLike = () => {
    dispatch(likeVideo({id,token}));
  };



  useEffect(() => {
    dispatch(fetchRelatedVideos({ tags, page: 1, limit: 10 }));
  }, [tags]);

  // useEffect(() => {
  //   const detectDevTools = () => {
  //     const threshold = 160;

  //     const widthThreshold = window.outerWidth - window.innerWidth > threshold;
  //     const heightThreshold =
  //       window.outerHeight - window.innerHeight > threshold;
  //     const start = new Date().getTime();
  //     for (let i = 0; i < 1e6; i++) {}
  //     const end = new Date().getTime();
  //     const executionTime = end - start;
  //     let isDebuggerPresent = false;
  //     const before = performance.now();
  //     console.log("Before: ", before);
  //     debugger;
  //     const after = performance.now();
  //     console.log("after: ", after);
  //     console.log("after2: ", after - before);
  //     if (after - before > 100) {
  //       isDebuggerPresent = true;
  //     }
  //     console.log("Is debug", isDebuggerPresent);
  //     if (
  //       widthThreshold ||
  //       heightThreshold ||
  //       executionTime > 200 ||
  //       isDebuggerPresent
  //     ) {
  //       window.history.back();
  //     }
  //   };

  //   const intervalId = setInterval(detectDevTools, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  useEffect(() => {
    const fetchM3u8Url = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/serveVideo/get-video-url-free/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "text",
          }
        );
        const m3u8Content = response.data;
        const blob = new Blob([m3u8Content], { type: "application/vnd.apple.mpegurl" });
        const m3u8Url = URL.createObjectURL(blob);
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.config.xhrSetup = (xhr) => {
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
          };
          hls.loadSource(m3u8Url);
          hls.attachMedia(videoRef.current);
        } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
          videoRef.current.src = m3u8Url;
        }
      } catch (error) {
        console.error("Error fetching m3u8 URL:", error);
      }
    };

    fetchM3u8Url();
  }, [API_BASE_URL, id]);

  return (
    <Box className="video-player-container">
      <Box className="video-section">
        <Box className="aspect-ratio-box">
          <video
            ref={videoRef}
            className="video-player"
            controls
            controlsList="nodownload"
          />
        </Box>
        <Typography className="video-title" variant="h6">
          {title}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          className="video-details"
        >
          <Box className="channel-info" display="flex" alignItems="center">
            <Avatar 
              alt={userId.fullName} 
              src={`${API_BASE_URL}${userId.avatar}`} 
              sx={{ width: 40, height: 40, mr: 2 }} 
            />
            <Link  to={userId?.shortUrl ? `/channel/${userId?.shortUrl}` : demoChannelUrl} state={{ userId }} className="channel-link">
              <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }}>
                {userId.fullName}
                <CheckCircleIcon className="verified-icon" />
              </Typography>
            </Link>
          </Box>
          <Box className="video-stats">
          <ThumbUpAltIcon onClick={handleLike}  sx={{ fontSize: "20px", color: "green", mr: 1 }}/>
            <Typography variant="body2">
              {parseInt(numOfView).toLocaleString()} views
            </Typography>
            <Typography variant="body2">
              {parseInt(numOfLike).toLocaleString()} likes
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {enumMode === "public" ? (
                <PublicIcon sx={{ fontSize: "16px", color: "green", mr: 1 }} />
              ) : (
                <LockIcon sx={{ fontSize: "16px", color: "red", mr: 1 }} />
              )}
              {enumMode === "public" ? "Public" : "Private"}
            </Typography>
          </Box>
        </Stack>

        
      </Box>
      <Box className="related-videos-section">
        <Box
          px={8}
          py={{ md: 1, xs: 5 }}
          justifyContent="center"
          alignItems="center"
        >
          <VideoRelative videos={dataRelateVideo.documents} />
        </Box>
      </Box>
      {adVisible && (
        <Box
          sx={{
            position: "fixed",
            top: "10%",
            right: 0,
            transform: "translateX(0%)",
            width: isMobile ? "200px" : "320px",
            height: isMobile ? "40px" : "50px",
            zIndex: 1000,
            backgroundColor: "#f0f0f0",
            display: "block",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1100,
              color: "#000",
              backgroundColor: "transparent",
              width: "24px",
              height: "24px",
              padding: "0",
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            }}
            onClick={() => setAdVisible(false)}
          >
            <CloseIcon />
          </IconButton>
          <div id="container-32fa159b2823d5614a98ed15df060211"></div>
        </Box>
      )}
      {adMobileVisible &&
        adWebVisible &&
        (isMobile ? (
          <AdBannerMobile setAdMobileVisible={setAdMobileVisible} />
        ) : (
          <AdBanner setAdWebVisible={setAdWebVisible} />
        ))}
    </Box>
  );
};

export default ViewVideoPlayer;
