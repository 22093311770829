import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  Box,
  Avatar,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import Hls from "hls.js";
import { useSelector } from "react-redux";
import {
  demoThumbnailUrl,
  demoVideoUrl,
  demoVideoTitle,
  demoChannelUrl,
  demoChannelTitle,
} from "../../utils/constants";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VideoCardPage = ({
  video: {
    _id,
    snippet,
    imageUrl,
    title,
    description,
    numOfView,
    numOfLike,
    enumMode,
    priceBNB,
    priceUSD,
    tags,
    dateCreate,
    lastUpdated,
    userId,
  },
}) => {
  const navigate = useNavigate();
  const videoRef = useRef(null); // Reference for the video element
  const { user, token } = useSelector((state) => state.auth);

  const handleTagClick = (tag) => {
    navigate(`/search/${tag}`);
  };

  const fetchM3u8Url = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/serveVideo/get-video-url-free/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "text",
        }
      );
      const m3u8Content = response.data;
      const blob = new Blob([m3u8Content], {
        type: "application/vnd.apple.mpegurl",
      });
      const m3u8Url = URL.createObjectURL(blob);
      
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.config.xhrSetup = (xhr) => {
          xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        };
        hls.loadSource(m3u8Url);
        hls.attachMedia(videoRef.current);
      } else if (
        videoRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = m3u8Url;
      }
    } catch (error) {
      console.error("Error fetching m3u8 URL:", error);
    }
  };

  const handleMouseEnter = async () => {
    try {
      if (!videoRef.current) return;
  
      // Fetch the m3u8 URL and set up the video source
      await fetchM3u8Url();
  
      // Wait for the video metadata to load before playing (ensure the video is ready)
      videoRef.current.onloadedmetadata = () => {
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.play().catch((error) => {
            if (error.name === 'AbortError') {
              console.log("Video play aborted: ", error.message);
            } else {
              console.error("Error playing the video:", error);
            }
          });
        }
      };
    } catch (error) {
      console.error("Error during video setup:", error);
    }
  };
  
  const handleMouseLeave = () => {
    if (videoRef.current) {
      // Check if the video is playing before calling pause
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
      videoRef.current.currentTime = 0; // Reset video to the start
    }
  };
  

  return (
    <Card
      sx={{
        position: "relative",
        width: { xs: "100%", sm: "358px", md: "320px" },
        boxShadow: "none",
        borderRadius: 3,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          background:
            enumMode === "public"
              ? "linear-gradient(45deg, #32CD32, #228B22)"
              : "linear-gradient(45deg, #FF4500, #FF0000)",
          color: "white",
          display: "flex",
          alignItems: "center",
          px: 2,
          py: 0.5,
          borderRadius: "0 0 12px 0",
          fontWeight: "bold",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }}
      >
        {enumMode === "public" ? (
          <PublicIcon sx={{ fontSize: 18, mr: 0.5 }} />
        ) : (
          <LockIcon sx={{ fontSize: 18, mr: 0.5 }} />
        )}
        {enumMode === "public" ? "Free" : "VIP"}
      </Box>

      <Link
        to={
          _id
            ? `/watchvideo/${enumMode === "public" ? _id : `private/${_id}`}`
            : `/video/cV2gBU6hKfY`
        }
        state={{
          title,
          description,
          numOfView,
          numOfLike,
          enumMode,
          priceBNB,
          priceUSD,
          tags,
          dateCreate,
          lastUpdated,
          userId,
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "358px" },
            height: 180,
            position: "relative",
          }}
          onMouseEnter={handleMouseEnter} // Start video on hover
          onMouseLeave={handleMouseLeave} // Pause video when hover ends
        >
          <video
            ref={videoRef}
            className="video-player"
            poster={`${API_BASE_URL}${imageUrl}` || demoThumbnailUrl}
            muted
            playsInline={false}
          />
        </Box>
      </Link>

      <CardContent sx={{ backgroundColor: "#1E1E1E", height: "auto" }}>
        <Link
          to={
            _id
              ? `/watchvideo/${enumMode === "public" ? _id : `private/${_id}`}`
              : demoVideoUrl
          }
          state={{
            title,
            description,
            numOfView,
            numOfLike,
            enumMode,
            priceBNB,
            priceUSD,
            tags,
            dateCreate,
            lastUpdated,
            userId,
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
            {title.slice(0, 60) || demoVideoTitle.slice(0, 60)}
          </Typography>
        </Link>

        <Link
          to={userId?.shortUrl ? `/channel/${userId?.shortUrl}` : demoChannelUrl}
          state={{ userId }}
        >
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            <Avatar
              src={`${API_BASE_URL}${userId?.avatarUrl || demoThumbnailUrl}`}
              alt={userId?.fullName || demoChannelTitle}
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="subtitle2" color="gray">
              {userId?.fullName || demoChannelTitle}
              <CheckCircleIcon
                sx={{ fontSize: "12px", color: "gray", ml: "5px" }}
              />
            </Typography>
          </Stack>
        </Link>

        {tags?.length > 0 && (
          <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                clickable
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#fbc50c",
                  "&:hover": {
                    backgroundColor: "#d4a000",
                  },
                }}
                onClick={() => handleTagClick(tag)}
              />
            ))}
          </Box>
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <VisibilityIcon sx={{ color: "gray", fontSize: 16 }} />
            <Typography variant="body2" color="gray">
              {numOfView ?? 0}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <ThumbUpIcon sx={{ color: "gray", fontSize: 16 }} />
            <Typography variant="body2" color="gray">
              {numOfLike ?? 0}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default VideoCardPage;
