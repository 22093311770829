import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Box,
  Avatar,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PublicIcon from "@mui/icons-material/Public";
import LockIcon from "@mui/icons-material/Lock";

import {
  demoThumbnailUrl,
  demoVideoUrl,
  demoVideoTitle,
  demoChannelUrl,
  demoChannelTitle,
} from "../../utils/constants";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StreamCard = ({
  video: { _id,linkstream, view, name, image, dataCookie,categoryIds },
}) => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Function to handle tag click
  const handleTagClick = (tag) => {
    navigate(`/search/${tag}`);
  };

  return (
    <Card
      sx={{
        position: "relative",
        width: { xs: "100%", sm: "358px", md: "320px" },
        boxShadow: "none",
        borderRadius: 3,
      }}
    >
      <Link to={linkstream ? `/live/${_id}` : `/video/cV2gBU6hKfY`} state={{ _id,linkstream, view, name, image, dataCookie }}>
        <CardMedia
          image={demoThumbnailUrl}
          alt={name}
          sx={{ width: { xs: "100%", sm: "358px" }, height: 180 }}
        />
      </Link>

      <CardContent sx={{ backgroundColor: "#1E1E1E", height: "auto" }}>
        <Link to={linkstream ? `/live/${_id}` : `/video/cV2gBU6hKfY`} state={{_id, linkstream, view, name, image, dataCookie }}>
          <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
            {name} is Streamingggggggg!!!!!!!!
          </Typography>
        </Link>

        <Link>
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            {/* <Avatar 
              src={`${API_BASE_URL}${userId?.avatarUrl || demoThumbnailUrl}`} 
              alt={name || demoChannelTitle} 
              sx={{ width: 24, height: 24 }} 
            /> */}
            <Typography variant="subtitle2" color="gray">
              {name || demoChannelTitle}
              <CheckCircleIcon
                sx={{ fontSize: "12px", color: "gray", ml: "5px" }}
              />
            </Typography>
          </Stack>
        </Link>

        {/* {tags?.length > 0 && (
          <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {tags.map((tag, index) => (
              <Chip 
                key={index} 
                label={tag} 
                size="small" 
                clickable
                  sx={{
                    borderRadius:2,
                    backgroundColor: "#fbc50c",
                    "&:hover": {
                      backgroundColor: "#d4a000",
                    },
                  }}
                onClick={() => handleTagClick(tag)} 
              />
            ))}
          </Box>
        )} */}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <VisibilityIcon sx={{ color: "gray", fontSize: 16 }} />
            <Typography variant="body2" color="gray">
              {view ?? 0}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <ThumbUpIcon sx={{ color: "gray", fontSize: 16 }} />
            <Typography variant="body2" color="gray">
               0
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default StreamCard;
